// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { ArrowTransferOutIcon, Button, DatePicker, InputAmount } from '@gamesb42/ui-kit';
import { Form, Modal } from 'antd';

import Flex from 'components/Flex';
import { amountWithCurr, formatInterest, replaceInString } from './helpers';
import { formatAmount, formatDate } from 'helpers/formatters';
import { activationOrderDataType } from 'hooks/api/useActivationOrder';
import { getToken } from 'helpers/token';

import s from './styles.module.scss';

const AvailableBalance = ({ status, balance }) => {
  if (status === 'default') {
    return (
      <Flex className={s.balanceRow} style={{ background: '#eaedf4' }} align="center" gap="32px">
        <div style={{ color: '#686F88' }}>Available balance:</div>
        <div>{balance}</div>
      </Flex>
    );
  }

  if (status === 'error') {
    return (
      <Flex className={s.balanceRow} style={{ background: '#FEECF1' }} align="center" gap="32px">
        <div style={{ color: '#686F88' }}>Available balance:</div>
        <div style={{ color: '#EC3E72' }}>
          {balance}
          {' Amount is not equal Available Balance'}
        </div>
      </Flex>
    );
  }

  return <></>;
};

export default function MainBlock({ activationOrderData }: { activationOrderData: activationOrderDataType }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [balanceRaw, setBalanceRaw] = useState('');
  const [balance, setBalance] = useState('');
  const [balanceStatus, setBalanceStatus] = useState('default');

  const createTransaction = () => {
    const values = form.getFieldsValue();
    console.log({ values });
    console.log({ balance });

    if (values.amount > balanceRaw) {
      setBalanceStatus('error');
      return;
    } else {
      setBalanceStatus('default');
    }
    const myHeaders = new Headers();
    myHeaders.append('authorization', `Bearer ${getToken()}`);
    myHeaders.append('Content-Type', 'application/json');

    const raw = JSON.stringify({
      amount: values.amount,
      tx_date: values.tx_date.format('YYYY-MM-DD'),
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch(
      `${process.env.REACT_APP_BASE_URL}/activation_order/future/${activationOrderData.id}/repayment`,
      requestOptions,
    )
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        setModalOpen(false);
      })
      .catch((error) => console.error(error));
  };

  const getBalance = (id) => {
    const myHeaders = new Headers();
    myHeaders.append('authorization', `Bearer ${getToken()}`);
    myHeaders.append('content-type', 'application/json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };

    fetch(`${process.env.REACT_APP_TS_URL}/v1/accounts/company-id/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const currencyCode = activationOrderData.currency === 'EUR' ? '978' : '840';
        const balanceVal = result.filter((el) => el.type === 'counterparty-client' && el.currency === currencyCode)[0]
          .balance;
        setBalanceRaw(balanceVal);

        if (balanceVal > 0) {
          setBalance(`${activationOrderData.currency === 'EUR' ? '€' : '$'}${balanceVal}`);
        } else {
          setBalance(
            `-${activationOrderData.currency === 'EUR' ? '€' : '$'}${formatAmount(balanceVal.toString().slice(1))}`,
          );
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getBalance(activationOrderData?.company_id);
  }, []);

  return (
    <>
      <Flex gap="32px">
        <Flex direction="column" className={s.block}>
          <Flex className={s.blockRow}>
            <div className={s.blockRowLabel}>Client:</div>
            <div className={s.blockRowLinkValue}>{activationOrderData.company_name}</div>
          </Flex>
          <Flex className={s.blockRow}>
            <div className={s.blockRowLabel}>Agreement:</div>
            <div className={s.blockRowLinkValue}>{activationOrderData.contract_number}</div>
          </Flex>
          <Flex className={s.blockRow}>
            <div className={s.blockRowLabel}>AO:</div>
            <div className={s.blockRowValue}>{activationOrderData?.name}</div>
          </Flex>
          <Flex className={s.blockRow}>
            <div className={s.blockRowLabel}>AO Date:</div>
            <div className={s.blockRowValue}>{formatDate(activationOrderData?.ao_document?.date as string)}</div>
          </Flex>
        </Flex>

        <Flex direction="column" className={s.block}>
          <Flex className={s.blockRow}>
            <div className={s.blockRowLabel}>Principal Repayment:</div>
            <Flex width="229px" align="center" justify="space-between" style={{ paddingRight: '16px' }}>
              <div className={s.blockRowValue}>
                {amountWithCurr(activationOrderData.currency, activationOrderData.principal_repayment as string)}
              </div>
              <Button type="iconBtn" icon={<ArrowTransferOutIcon />} onClick={() => setModalOpen(true)} />
            </Flex>
          </Flex>
          <Flex className={s.blockRow}>
            <div className={s.blockRowLabel}>VAT policy:</div>
            <div className={s.blockRowValue}>{replaceInString(activationOrderData.vat_policy as string)}</div>
          </Flex>
          <Flex className={s.blockRow}>
            <div className={s.blockRowLabel}>Default Interest:</div>
            <div className={s.blockRowValue}>
              {formatInterest(replaceInString(activationOrderData?.penalty_policy as string))}
            </div>
          </Flex>
        </Flex>
      </Flex>

      <Flex gap="32px">
        <Flex direction="column" className={s.block}>
          <Flex className={s.blockRow}>
            <div className={s.blockRowLabel}>Purchase Price:</div>
            <div className={s.blockRowValue}>
              {amountWithCurr(activationOrderData.currency, activationOrderData.purchase_price as string)}
            </div>
          </Flex>
          <Flex className={s.blockRow}>
            <div className={s.blockRowLabel}>Purchased Receivables:</div>
            <div className={s.blockRowValue}>
              {amountWithCurr(activationOrderData.currency, activationOrderData.purchased_receivables as string)}
            </div>
          </Flex>
          <Flex className={s.blockRow}>
            <div className={s.blockRowLabel}>Purchase date:</div>
            <div className={s.blockRowValue}>{formatDate(activationOrderData?.purchase_date as string)}</div>
          </Flex>
        </Flex>

        <Flex direction="column" className={s.block}>
          <Flex className={s.blockRow}>
            <div className={s.blockRowLabel}>First Repayment date:</div>
            <div className={s.blockRowValue}>{formatDate(activationOrderData?.first_repayment_date as string)}</div>
          </Flex>
          <Flex className={s.blockRow}>
            <div className={s.blockRowLabel}>Final Repayment date:</div>
            <div className={s.blockRowValue}>{formatDate(activationOrderData?.final_repayment_date as string)}</div>
          </Flex>
        </Flex>
      </Flex>
      <Modal
        className={s.createT}
        title="Create transaction"
        open={modalOpen}
        okText="Create transaction"
        onOk={() => createTransaction()}
        onCancel={() => setModalOpen(false)}
        centered
      >
        <Form form={form}>
          <Flex direction="column">
            <Flex gap="32px">
              <Form.Item name="tx_date" rules={[{ required: true, message: 'Please input date' }]}>
                <DatePicker
                  // onChange={(e) => {
                  //   if (e) {
                  //     setTransactionDate({ ...transactionData, tx_date: e.format('YYYY-MM-DD') });
                  //   } else {
                  //     setTransactionDate({ ...transactionData, tx_date: '' });
                  //   }
                  // }}
                  style={{ width: '343px' }}
                  label="Date"
                  size="large"
                  format="DD.MM.YYYY"
                  aria-required
                />
              </Form.Item>
              <Form.Item name="amount" rules={[{ required: true, message: 'Please input amount' }]}>
                <InputAmount
                  style={{ width: '343px' }}
                  label="Amount"
                  currency={activationOrderData?.currency!}
                  size="large"
                />
              </Form.Item>
            </Flex>
            <AvailableBalance status={balanceStatus} balance={balance} />
          </Flex>
        </Form>
      </Modal>
    </>
  );
}
