import React, { CSSProperties, ReactNode } from 'react';

interface FlexProps {
  children: ReactNode;
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  justify?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
  align?: 'stretch' | 'flex-start' | 'flex-end' | 'center' | 'baseline';
  gap?: string;
  className?: string;
  style?: CSSProperties;
  width?: string;
}

const Flex: React.FC<FlexProps> = ({
  children,
  direction = 'row',
  justify = 'flex-start',
  align = 'stretch',
  gap = '0px',
  className = '',
  style = {},
  width = '100%',
}) => {
  return (
    <div
      className={className}
      style={{
        display: 'flex',
        width,
        flexDirection: direction,
        justifyContent: justify,
        alignItems: align,
        gap,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default Flex;
