export const getSymbolAndColorTransactions = (amount: string | number | undefined, type: string) => {
  const amountLocal = Number(amount || 0);
  let symbol = amountLocal ? '−' : '';
  let color = '';

  if (type === 'incoming') {
    symbol = '+';
    color = '#26B634';
  }

  if (type === 'adjustment') {
    symbol = amountLocal < 0 ? '−' : '+';
    color = amountLocal > 0 ? '#26B634' : '';
  }

  if (type === 'fee') {
    symbol = amountLocal < 0 ? '+' : '-';
    color = amountLocal < 0 ? '#26B634' : '';
  }

  return {
    color,
    symbol,
  };
};

export const capitalizeFirstLetter = (str: string) => {
  if (typeof str !== 'string' || str.length === 0) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const isInRange = (dates: Date[], checkDate: Date) => checkDate >= dates[0] && checkDate <= dates[1];
