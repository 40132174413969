// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useCompany from 'hooks/api/useCompany';
import useActivationOrder, {
  initialActivationOrderTableParams,
  useActivationOrderType,
} from 'hooks/api/useActivationOrder';
import useErrorCallback from 'hooks/useErrorCallback';
import { RefreshContext } from 'contexts/RefreshContext';
import useRouteTitle from 'hooks/useRouteTitle';
import { autocompleteContractData } from 'helpers/transformDataForAutocomplete';
import { filterAo, filterCompanies } from 'helpers/companies';
import setUrlStringParams from 'helpers/setUrlStringParams';
import { AOFilters } from 'consts/tableFilters';
import useContracts from 'hooks/api/useContracts';
import Title from 'components/common/Title';
import SelectButton from 'components/SelectButton';
import urls from 'constants/urls';
import { listStatusAo, typeAo, newTypeAo } from 'components/forms/ActivationOrderForm/CardsAo/data';
import { DropdownKit, DropdownSizeEnum } from 'components/uiKit/DropdownKit';
import Select from 'components/uiKit/Select';
import CompanySelect from 'components/CompanySelect';

import styles from './styles.module.scss';

const ActivationTableHeader = ({ requestParams }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { title } = useRouteTitle();
  const { contractsData, getDocumentsByIdV2 } = useContracts();
  const {
    activationOrdersTableParams,
    setActivationOrdersTableParams,
    getActivationOrderV2,
    clearActivationOrdersV2Data,
    activationOrdersV2Data,
    listOrderAo,
  }: useActivationOrderType = useActivationOrder();
  const { errorCallback } = useErrorCallback();
  const refreshContext = useContext(RefreshContext);

  const aoList = useMemo(() => filterAo(listOrderAo.records), [listOrderAo.records]);
  const ao = useMemo(
    () =>
      activationOrdersV2Data?.records &&
      activationOrdersV2Data?.records.find((item) => item.id === activationOrdersTableParams?.order_id),
    [listOrderAo, activationOrdersTableParams],
  );

  useEffect(() => {
    const newParams = refreshContext.getNewParams({
      datakey: 'activationOrdersTableParams',
      params: activationOrdersTableParams,
      requestParams,
    });
    const params = {
      ...newParams,
      urlFiltersIsSet: true,
    };
    setActivationOrdersTableParams(params);

    return () => {
      setActivationOrdersTableParams(initialActivationOrderTableParams);
      clearActivationOrdersV2Data();
    };
  }, []);

  useEffect(() => {
    if (!activationOrdersTableParams.urlFiltersIsSet) return;
    getActivationOrderV2({ params: activationOrdersTableParams, errorCallback });

    if (activationOrdersTableParams?.company_id) {
      getDocumentsByIdV2({
        id: activationOrdersTableParams.company_id,
        endpoint: 'getContracts',
        resultKey: 'contractsData',
        errorCallback,
      });
    }

    if (activationOrdersTableParams?.company_id) {
      getActivationOrderV2({
        params: {
          company_id: activationOrdersTableParams.company_id,
          order_id: activationOrdersTableParams.order_id,
          contract_number: activationOrdersTableParams.contract_number,
          status: activationOrdersTableParams.status,
          _sort: 'name',
          limit: 10000,
        },
        resultKey: 'getListOrderAo',
        errorCallback,
      });
    }
  }, [activationOrdersTableParams]);

  useEffect(() => {
    refreshContext.setParams({ datakey: 'activationOrdersTableParams', params: activationOrdersTableParams });
    setUrlStringParams({ filters: AOFilters, currentScreenParams: activationOrdersTableParams });
  }, [activationOrdersTableParams]);

  const createHandler = useCallback(
    (i: number) => {
      history.push(`${urls.getActivationOrderCreateLink()}/${typeAo[i].key}`);
    },
    [history],
  );

  enum InitialAoEnum {
    CONTRACT_NUMBER = 'contract_number',
    COMPANY_ID = 'company_id',
    ORDER_ID = 'order_id',
    STATUS = 'status',
  }

  const setInitialData = useCallback(
    (key: InitialAoEnum, value) => {
      const params = { ...activationOrdersTableParams, [key]: value };

      if (key === InitialAoEnum.STATUS) {
        params.page = 0;
      }

      if (key === InitialAoEnum.COMPANY_ID) {
        params.contract_number = '';
        params.order_id = '';
        params.page = 0;
      }

      if (key === InitialAoEnum.CONTRACT_NUMBER) {
        params.order_id = '';
      }
      setActivationOrdersTableParams(params);
    },
    [setActivationOrdersTableParams, activationOrdersTableParams],
  );

  return (
    <div>
      <Title text={title} />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          marginTop: 26,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            flex: '1 1 auto',
            marginBottom: 20,
          }}
        >
          <CompanySelect
            label={t('tables.activationOrder.header.client')}
            size="middle"
            className={styles.companySelect}
            value={activationOrdersTableParams?.company_id || undefined}
            onChange={(id) => setInitialData(InitialAoEnum.COMPANY_ID, id || '')}
            allowClear
          />
          {activationOrdersTableParams.company_id && (
            <DropdownKit
              label={t('tables.activationOrder.header.agreement')}
              options={autocompleteContractData(contractsData?.contracts)}
              value={activationOrdersTableParams.contract_number}
              changeSelectItem={(_, data) => {
                setInitialData(InitialAoEnum.CONTRACT_NUMBER, data?.id || '');
              }}
              keyObj="name"
              wrapStyle={{ width: 240, marginRight: 20 }}
              positionType={DropdownSizeEnum.SIZE_40}
            />
          )}
          {activationOrdersTableParams.company_id && (
            <DropdownKit
              label={t('tables.activationOrder.header.ao')}
              options={aoList}
              value={ao?.name}
              changeSelectItem={(_, data) => {
                setInitialData(InitialAoEnum.ORDER_ID, data?.id || '');
              }}
              keyObj="name"
              wrapStyle={{ width: 240, marginRight: 20 }}
              positionType={DropdownSizeEnum.SIZE_40}
            />
          )}
          <Select
            label={t('tables.activationOrder.header.status')}
            options={listStatusAo.map((e) => ({ key: e.key, value: e.name }))}
            value={activationOrdersTableParams.status?.toUpperCase()}
            onChange={(key = '') => setInitialData(InitialAoEnum.STATUS, key.toLowerCase())}
            width="150px"
          />
        </div>
        {/* <div style={{ marginBottom: 20 }}>
          <SelectButton
            title={t('tables.activationOrder.createButton')}
            list={newTypeAo.filter((el) => el.name !== 'NOW').map((item) => item.name)}
            callback={createHandler}
          />
        </div> */}
      </div>
    </div>
  );
};

export default ActivationTableHeader;
