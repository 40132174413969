// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Button } from '@material-ui/core';
import React, { FC, Children, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { InputNumber, InputAmount } from '@gamesb42/ui-kit';

import DateInput from 'components/inputs/DateInput';
import { AmountKit } from 'components/uiKit/AmountKit';
import { SelectKit } from 'components/uiKit/SelectKit';
import usePlatform, { PlatformDataType, usePlatformType } from 'hooks/api/usePlatform';
import { UseStylingDropZoneButton } from 'hooks/useStylingForMaterialUi';
import { CurrencyType } from 'components/uiKit/InputKit';

import stylesSelect from 'components/uiKit/SelectKit/styles.module.scss';
import styles from '../../PurchaseRequestForm/Form/View/Claim/styles.module.scss';

import {
  distributionPlatformsType,
  getCurrencyList,
  getListDistributionPlatform,
  getValueCurrency,
  getValueDistributionPlatform,
  KeyCardsEnum,
} from './data';
import TemplateGrid from './TemplateGrid';
import useCurrentRequiredKeyOfCard from './useCurrentRequiredKeyOfCard';
import useErrors from './useErrors';

const plusSvg = require('assets/img/plus.svg').default;
const basketSvg = require('assets/img/basket.svg').default;

interface SwitchCustomSettingsType {
  style?: React.CSSProperties;
  data: distributionPlatformsType[];
  onChange?: (key, value, i) => void;
  callback?: () => void;
  deleteCallback?: (i: number) => void;
  itemKey?: KeyCardsEnum;
  currency?: CurrencyType;
}
const DistributionPlatformFACreate: FC<SwitchCustomSettingsType> = ({
  style,
  data,
  onChange = () => {},
  callback = () => {},
  deleteCallback = () => {},
  itemKey,
  currency,
}) => {
  const { errors } = useErrors();
  const { t } = useTranslation();
  const dropZoneStylingButton = UseStylingDropZoneButton();
  const { platformData, getDocuments }: usePlatformType = usePlatform();
  const { getParamsItem } = useCurrentRequiredKeyOfCard();

  useEffect(() => {
    getDocuments({
      endpoint: 'getPlatformsFactoring',
      resultKey: 'platformData',
      params: { show_deleted: false },
    });
  }, []);

  return (
    <div style={{ ...style, height: '100%', display: 'grid', gap: 40 }}>
      {Array.isArray(data) &&
        Children.toArray(
          data.map(({ platform_id, minimal_repayment_percent, minimal_repayment_amount }, i) => (
            <div style={{ position: 'relative', border: '1px solid #CED0D7', borderRadius: 8, padding: 12 }}>
              <TemplateGrid>
                <SelectKit
                  label={t('forms.activationOrder.distributionPlatform')}
                  options={getListDistributionPlatform(platformData)}
                  val={getValueDistributionPlatform(platform_id, platformData)?.name}
                  changeSelectItem={(_, value) => {
                    onChange(KeyCardsEnum.PLATFORM_ID, value.id, i);
                  }}
                  keyObj="name"
                  withAllOption={false}
                  required={getParamsItem(itemKey, KeyCardsEnum.PLATFORM_ID)?.required}
                  isError={!!errors[`${KeyCardsEnum.PLATFORM_ID}_${i}`]}
                  helperText={errors[`${KeyCardsEnum.PLATFORM_ID}_${i}`]}
                  wrapProps={{ height: 60 }}
                  customOption={(item: PlatformDataType, isActive, handleMouseDown) => (
                    <div
                      onMouseDown={handleMouseDown}
                      className={stylesSelect.menuSelectItemStyle}
                      style={{
                        display: 'grid',
                        gridAutoFlow: 'column',
                        justifyContent: 'start',
                        gridTemplateColumns: '20px 1fr',
                        gap: 15,
                        backgroundColor: !isActive ? '#F3F3F4' : undefined,
                      }}
                    >
                      <img src={item?.logo} alt="" style={{ width: 24 }} />
                      {item.name}
                    </div>
                  )}
                  startAdornment={
                    getValueDistributionPlatform(platform_id, platformData)?.logo ? (
                      <img
                        style={{
                          zIndex: 2,
                          width: 24,
                          height: 24,
                          marginTop: 25,
                          marginLeft: 18,
                        }}
                        className={styles.inputImg}
                        src={getValueDistributionPlatform(platform_id, platformData)?.logo}
                        alt={getValueDistributionPlatform(platform_id, platformData)?.name}
                      />
                    ) : undefined
                  }
                />
              </TemplateGrid>
              <TemplateGrid>
                <InputNumber
                  required={true}
                  label="Minimal Repayment, %*"
                  size="large"
                  min={0}
                  max={100}
                  value={minimal_repayment_percent}
                  onChange={(e) => {
                    onChange(KeyCardsEnum.MINIMAL_REPAYMENT_PERCENT, e || null, i);
                  }}
                />
                <InputAmount
                  required={true}
                  value={minimal_repayment_amount}
                  onChange={(e) => {
                    if (e) onChange(KeyCardsEnum.MINIMAL_REPAYMENT_AMOUNT, e || null, i);
                  }}
                  label="Minimal Repayment Amount*"
                  currency={currency}
                  size="large"
                />
              </TemplateGrid>
              {data.length > 1 && (
                <img
                  src={basketSvg}
                  alt="basket"
                  onClick={() => deleteCallback(i)}
                  style={{
                    alignSelf: 'center',
                    position: 'absolute',
                    padding: 10,
                    border: '1px solid #A7AFB7',
                    borderRadius: 8,
                    cursor: 'pointer',
                    right: -61,
                    top: 10,
                  }}
                />
              )}
            </div>
          )),
        )}
      <Button
        onClick={callback}
        {...dropZoneStylingButton}
        style={{ width: 258, height: 40, whiteSpace: 'nowrap' }}
        startIcon={<img className="dropZoneImg" src={plusSvg} alt="plus" />}
      >
        {t('forms.activationOrder.addDistributionPlatform')}
      </Button>
    </div>
  );
};

export default DistributionPlatformFACreate;
