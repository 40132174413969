import React, { Children, FC, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import useCommissionReport from 'hooks/api/useCommissionReport';
import { formatDate, getAmountWithCorrectSign } from 'helpers/formatters';
import { tableBodyStyles, tableSettingsStyles } from 'theme/styles';
import TableWrapWithPagination from 'components/tables/TableWrapWithPagination';
import SortTable from 'components/SortTable';

import { dataCommissionReport, getKeyCommissionReporting, NameKeyCommissionReport } from './commissionReportData';
import baseRequest from 'queries/baseRequest';
import endpoints from 'consts/endpoints';
import s from './styles.module.scss';

const KEYS_FOR_CHECK_ZERO = [
  NameKeyCommissionReport.LOAN_BODY,
  NameKeyCommissionReport.COMMISSION_ACCRUED,
  NameKeyCommissionReport.INTERESTS,
  NameKeyCommissionReport.DEFAULT_INTEREST_ACCRUED,
  NameKeyCommissionReport.PENALTIES,
];
// @ts-ignore
const CommissionReportTable: FC | any = ({ requestParams }) => {
  const classes = tableSettingsStyles();
  const styles = tableBodyStyles();
  const { t } = useTranslation();
  const { commissionReportsData, commissionReportTableParams, setCommissionReportTableParams } = useCommissionReport();

  const commissionReports = useMemo(() => {
    let resultCommissionReports = commissionReportsData.report ? commissionReportsData.report : commissionReportsData;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    resultCommissionReports = resultCommissionReports.filter((item: any) => {
      const values: string[] = [];
      KEYS_FOR_CHECK_ZERO.forEach((key) => values.push(item[key]));
      const result = values.some((value) => Number(value) !== 0);

      return result;
    });

    return resultCommissionReports;
  }, [commissionReportsData]);

  const grandTotal = useMemo(
    () => (commissionReportsData.grand_total ? commissionReportsData.grand_total : null),
    [commissionReportsData],
  );
  const { _sort, _order } = useMemo(() => commissionReportTableParams, [commissionReportTableParams]);
  const dtFrom = new Date(commissionReportTableParams.report_date);
  dtFrom.setDate(dtFrom.getDate() - 1);
  const date = formatDate(dtFrom);
  const [currentClient, setCurrentClient] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeSorting = useCallback(
    (label, orderLocal) => {
      if (!label || !orderLocal) return;
      setCommissionReportTableParams({
        ...commissionReportTableParams,
        _order: orderLocal,
        _sort: label,
      });
    },
    [commissionReportTableParams, setCommissionReportTableParams],
  );

  const headerAddClasses = useMemo(
    () =>
      commissionReports && commissionReports.length > 0 ? ` ${styles.tHeadRowth1stSticky} ${styles.th2ndSticky}` : '',
    [commissionReports, styles.tHeadRowth1stSticky, styles.th2ndSticky],
  );

  const headerHeight = 101;

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
      baseRequest({
        path: endpoints.getAccountsUrl({
          size: 999,
          type: 'counterparty-client',
          'balance-date': requestParams.report_date,
        }),
      })
        .then(({ items }) => {
          const client = items.filter((elem: any) => elem.company_id === requestParams.company_id);
          setCurrentClient(client);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [requestParams]);

  return (
    <TableWrapWithPagination
      count={100}
      onPageChange={() => {}}
      onRowsPerPageChange={() => {}}
      styleLeft={{ left: 312 }}
      styleTop={{ top: headerHeight }}
      isShowPagination={false}
    >
      <TableHead>
        <TableRow
          classes={{
            root: `${classes.headTableRowRoot} ${classes.headTableRowRootIcon} ${headerAddClasses}`,
          }}
          className={s.header}
          style={{ height: headerHeight }}
        >
          {Children.toArray(
            dataCommissionReport.map(({ key, align, translate, dateData, typeSort, isHiddenSort }) => (
              <TableCell
                classes={{ root: classes.headTableCellRoot }}
                align={align || 'right'}
                style={{ verticalAlign: 'bottom' }}
              >
                <SortTable
                  isHiddenSort={isHiddenSort}
                  onClickHandler={(order) => handleChangeSorting(key, order)}
                  isActive={_sort === key}
                  order={_order}
                  type={typeSort}
                >
                  {t(translate, dateData ? { date } : undefined)}
                  {!isHiddenSort && _sort === key ? (
                    <span className={styles.visuallyHidden}>
                      {_order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </SortTable>
              </TableCell>
            )),
          )}
        </TableRow>
      </TableHead>
      <TableBody classes={{ root: classes.bodyTableRoot }}>
        {Children.toArray(
          commissionReports.map(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (item) => (
              <TableRow
                classes={{
                  root: `${classes.bodyTableRowRoot} ${classes.bodyTableRowRootTd2} ${styles.tBodyRowSticky} ${styles.td2ndSticky}`,
                }}
                hover
              >
                {dataCommissionReport.map(({ align, key }) => (
                  <TableCell
                    align={align || 'inherit'}
                    className={classNames({
                      [styles.fixedCell]: key === NameKeyCommissionReport.COMPANY_NAME,
                      [styles.nameWidth]: key === NameKeyCommissionReport.COMPANY_NAME,
                      [styles.fixedCell2]: key === NameKeyCommissionReport.NAME,
                    })}
                    classes={{ root: classes.bodyTableCellRoot }}
                  >
                    {getKeyCommissionReporting(key, item[key], item.currency, item.id)}
                  </TableCell>
                ))}
              </TableRow>
            ),
          ),
        )}
        <TableRow hover className={`${styles.tBodyRowSticky} ${styles.td2ndSticky} ${styles.td3rdSticky}`}>
          <TableCell>Client Balance</TableCell>
          {currentClient?.length > 0 &&
            currentClient.map((elem, i) => (
              <TableCell>
                {currentClient[i]?.balance}{' '}
                {currentClient[i]?.currency === '978' ? 'EUR' : currentClient[i]?.currency === undefined ? '' : 'USD'}
              </TableCell>
            ))}
        </TableRow>
        {grandTotal && (
          <TableRow
            key="grand_total"
            hover
            className={`${styles.tBodyRowSticky} ${styles.td2ndSticky} ${styles.td3rdSticky}`}
          >
            <TableCell>{t('reports.grandTotal')}</TableCell>
            {Children.toArray(
              grandTotal.map(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                (body) => <TableCell>{getAmountWithCorrectSign(body.value || 0, body.currency)}</TableCell>,
              ),
            )}
          </TableRow>
        )}
      </TableBody>
    </TableWrapWithPagination>
  );
};

export default CommissionReportTable;
