//@ts-nocheck
import React, { useEffect, FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { PageHeader, Select as UIKITSelect, Button, DownloadIcon } from '@gamesb42/ui-kit';

import useRouteTitle from 'hooks/useRouteTitle';
import DateInput from 'components/inputs/DateInput';
import urls from 'constants/urls';
import SelectButton from 'components/SelectButton';
import useTransaction, { useTransactionType } from 'hooks/api/useTransaction';
import { TransactionTableFilters } from 'consts/tableFilters';
import { getUrlStringParams } from 'helpers/setUrlStringParams';
import { removeEmptyValuesFromObject } from 'helpers/dt';
import { InputKit } from 'components/uiKit/InputKit';

import {
  KeyTransactions,
  getListValueTypeTransactions,
  getObjectTransactions,
  getCurrentObjCurrency,
  getCurrencyListTransactions,
  getTypeTransactions,
} from 'components/tables/TransactionsTable/data';
import Select from 'components/uiKit/Select';
import CompanySelect from 'components/CompanySelect';
import useDebounce from '../../../hooks/useDebounce';
import { getListTransactionCategory } from './ViewsForm/data';
import DownloadLink from './DownloadLink';
import TransactionsMenu from './TransactionsMenu';
import { getFileFA } from 'components/forms/CompanyForm/reportsData';
import endpoints from 'consts/endpoints';

import s from './styles.module.scss';

const currentPath = [
  `${urls.getTransactionsCreate()}/incoming`,
  `${urls.getTransactionsCreate()}/outgoing`,
  `${urls.getTransactionsCreate()}/internal`,
  `${urls.getTransactionsCreate()}/fee`,
  `${urls.getTransactionsCreate()}/adjustment`,
  `${urls.getTransactionsCreate()}/conversion`,
  urls.getConversionTransactionCreate(),
];

interface TransactionTableHeaderPropsType {
  requestParams: { filter: string; sort: string };
  isChecked: boolean;
  setIsChecked: (data: boolean) => void;
}

const TransactionsTableHeader: FC<TransactionTableHeaderPropsType> = ({ requestParams, isChecked, setIsChecked }) => {
  const { t } = useTranslation();
  const { title } = useRouteTitle();
  const { getListTransactions, getArchiveTransactions }: useTransactionType = useTransaction();
  const history = useHistory();
  const { filter, sort } = requestParams;
  const { type, currency, decodeList, category, date_from, date_to, amount, client_id } = useMemo(() => {
    let filterArray: {
      type: { key?: string; value?: string };
      currency: {
        key: string;
        value: string;
      };
      category: string;
      date_from: string;
      date_to: string;
      amount: string;
      client_id: string;
      decodeList: [];
    } = {
      type: {},
      currency: {
        key: '',
        value: '',
      },
      category: '',
      date_from: '',
      date_to: '',
      amount: '',
      client_id: '',
      decodeList: [],
    };

    if (!filter) return filterArray;
    const decodeFilter: { property: string; operator: string; value: string }[] = JSON.parse(decodeURI(filter));

    if (Array.isArray(decodeFilter)) {
      decodeFilter.forEach((item) => {
        if (item.property === 'type') {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          filterArray = { ...filterArray, [item.property]: getObjectTransactions()[item.value] };
        }

        if (item.property === 'currency') {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          filterArray = { ...filterArray, [item.property]: getCurrentObjCurrency[item.value] };
        }

        if (item.property !== 'type' && item.property !== 'currency') {
          filterArray = { ...filterArray, [item.property]: item.value };
        }
      });
    }

    return { ...filterArray, decodeList: decodeFilter };
  }, [filter]);
  const [value, setValue] = useState([]);
  const { pathname, search } = useLocation();
  const getListOfTransactions = useCallback(
    (params) =>
      isChecked
        ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          getArchiveTransactions({ resultKey: 'listTransactionData', params })
        : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          getListTransactions({ params }),
    [isChecked],
  );

  useEffect(() => {
    getListOfTransactions(requestParams);
  }, [isChecked]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const categoryOptions = type.key ? getListTransactionCategory[KeyTransactions[type.key.toUpperCase()]]() : [];

  const getListTransactionDebounce = useDebounce((getTransactions: () => void) => {
    getTransactions();
  }, 500);

  useEffect(() => {
    const newPath = getUrlStringParams({
      filters: TransactionTableFilters,
      currentScreenParams: removeEmptyValuesFromObject({ ...requestParams }),
    });

    history.replace(newPath);
  }, []);

  const setParams = useCallback(
    (
      param: {
        type?: string;
        currency?: string;
        category?: string;
        date_from?: string;
        date_to?: string;
        amount?: string;
        client_id?: string;
      },
      operator = '=',
    ) => {
      // NOTE: почему считывается только первый показатель, из-за этого бага при сбрасывании param.category
      const value = Object.values(param)[0];
      const key = Object.keys(param)[0];
      const filterWithoutCurrentParams = decodeList.filter((item) => item.property !== key);
      const current = { property: key, operator, value };
      let filterParams = value ? [...filterWithoutCurrentParams, current] : filterWithoutCurrentParams;

      if (param.category === '') {
        filterParams = filterParams.filter((item) => item.property !== 'category');
      }
      const result =
        filterParams.length === 0
          ? { filter: '', page: 1 }
          : { filter: encodeURI(JSON.stringify([...filterParams])), page: 1 };
      const newPath = getUrlStringParams({
        filters: TransactionTableFilters,
        currentScreenParams: removeEmptyValuesFromObject({ ...requestParams, ...result }),
      });
      history.replace(newPath);

      if (current.property !== 'amount') {
        getListOfTransactions(removeEmptyValuesFromObject({ ...requestParams, ...result }));
      }

      if (current.property === 'amount') {
        getListTransactionDebounce(() =>
          getListOfTransactions(removeEmptyValuesFromObject({ ...requestParams, ...result })),
        );
      }
    },
    [requestParams],
  );

  const handleDownload = () => {
    const parseFilter = filter
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        JSON.parse(decodeURI(filter)).map((item) => {
          if (item.property === 'date_from') {
            return { property: 'value_datetime', operator: item?.operator, value: item?.value };
          }

          if (item.property === 'date_to') {
            return { property: 'value_datetime', operator: item?.operator, value: item?.value };
          }

          if (item.property === 'amount') {
            return { property: 'amount', operator: item?.operator, value: Number(item?.value) };
          }

          return item;
        })
      : {};

    getFileFA(
      endpoints.downloadArchiveTransactionURL({
        format: 'xlsx',
        ...(filter && { filter: JSON.stringify(parseFilter) }),
        ...(sort && { sort }),
      }),
      undefined,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    );
  };

  return (
    <div>
      <PageHeader
        title={title}
        className={s.headerTitle}
        rightContent={
          <SelectButton
            title={t('tables.createTransaction')}
            list={getTypeTransactions()}
            callback={(i) => history.push(currentPath[i])}
          />
        }
      />
      <div>
        <div className={s.menuWrapper}></div>
        <div className={s.headerWrap}>
          <div className={s.headerWrapRow}>
            <div className={s.headerWrapRowLeft}>
              {/* <DownloadLink filter={filter} sort={sort} /> */}
              <DateInput
                isNotErrorLocal
                date={date_from}
                labelKey="tables.dateFrom"
                name="date_from"
                onChangeDateHandler={({ target: { value } }) => {
                  if (value !== 'finish') setParams({ date_from: value }, '>=');
                }}
                commonStyle={{ height: 40, width: 200 }}
              />
              <DateInput
                isNotErrorLocal
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                date={date_to || null}
                labelKey="tables.dateTo"
                name="date_to"
                onChangeDateHandler={({ target: { value } }) => {
                  if (value !== 'finish') setParams({ date_to: value }, '<');
                }}
                commonStyle={{ height: 40, width: 200 }}
              />
              <Select
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                value={type.key}
                label={t('forms.transaction.type')}
                options={getListValueTypeTransactions()}
                onChange={(newType) => {
                  setParams({ type: newType, category: '' });
                }}
                width="256px"
              />
              <UIKITSelect
                mode="multiple"
                size="middle"
                label="Category"
                value={value}
                width="260px"
                allowClear
                value={value}
                onChange={(newCategory) => {
                  if (newCategory.length > 0) {
                    setParams({ category: newCategory }, 'in');
                  } else {
                    setParams({ category: '' });
                  }
                  setValue(newCategory);
                }}
                options={[
                  { label: 'Purchase Price', value: 'outgoing-purchase-price' },
                  { label: 'Balance Payable', value: 'outgoing-balance-payable' },
                  { label: 'Transit', value: 'outgoing-transit' },
                  { label: 'Commission', value: 'internal-commission' },
                  { label: 'Principal Amount', value: 'internal-principal-amount' },
                  { label: 'Default Interest', value: 'internal-default-interest' },
                  { label: 'Capitalization', value: 'internal-capitalization' },
                  { label: 'Factoring Service Fee', value: 'internal-factoring-services-fee' },
                ]}
              />
              <Select
                label={t('contentHeader.currency')}
                options={getCurrencyListTransactions}
                value={currency.key}
                onChange={(newCurrency) => {
                  setParams({ currency: newCurrency });
                }}
                width="112px"
              />
            </div>
            <Button
              style={{ width: '40px', height: '40px' }}
              icon={<DownloadIcon size={16} />}
              onClick={handleDownload}
            />
          </div>

          <div className={s.headerWrapRow}>
            <div className={s.headerWrapRowLeft}>
              <InputKit
                label={t('forms.transaction.amount')}
                onChange={(e) => {
                  setParams({ amount: e.target.value });
                }}
                value={amount}
                wrapProps={{ height: 40, width: 256 }}
              />
              <CompanySelect
                label={t('tables.client')}
                size="middle"
                width="320px"
                value={client_id || undefined}
                onChange={(id) => setParams({ client_id: id })}
                allowClear
              />
            </div>

            <TransactionsMenu isChecked={isChecked} setIsChecked={setIsChecked} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionsTableHeader;
