import React, { FC, useEffect, useState, useCallback, useRef } from 'react';

import { getCurrency } from 'helpers/formatters';

import styles from './styles.module.scss';

export type CurrencyType = 'EUR' | 'USD' | '';

export interface InputKitType {
  label?: string;
  value?: string;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlurHandler?: (e: React.FocusEvent<HTMLInputElement>) => void;
  wrapProps?: React.CSSProperties;
  isError?: boolean;
  helperText?: string;
  disabled?: boolean;
  name?: string;
  tooltip?: string;
  required?: boolean;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  offsetLeft?: number;
  currency?: CurrencyType;
  fontSize?: number;
  isSelect?: boolean;
  element?: React.ReactNode;
  selectOffsetRight?: number;
  getInputRef?: (data: React.MutableRefObject<any>) => void;
  onFocus?: (data: React.FocusEvent<HTMLInputElement>) => void;
  startAdornment?: React.ReactNode;
  helperTextStyle?: React.CSSProperties;
  dataCy?: string;
}

interface StyleType {
  padding?: string;
  opacity?: number;
  color?: string;
  border?: string;
  left?: number;
  top?: number | string;
  fontSize?: number;
  background: string;
  zIndex?: number;
  borderRadius?: string;
}

const offsetBottom = 10;
const offsetRight = 20;
const offsetTop = 8;
const fontSizeLabel = 16;
const type = 'text';
const labelId = `labelInputKit_`;
const red = '#EC3E72';

export const InputKit: FC<InputKitType> = ({
  value = '',
  onChange = () => {},
  label,
  wrapProps,
  placeholder,
  isError = false,
  helperText = '',
  disabled,
  onBlurHandler = () => {},
  name,
  required,
  onPaste,
  offsetLeft = 20,
  selectOffsetRight = 52,
  currency,
  fontSize = 16,
  tooltip = '',
  isSelect,
  element,
  getInputRef,
  onFocus,
  startAdornment,
  helperTextStyle,
  dataCy,
}) => {
  const inputRef = useRef(null);
  const [isFocus, setIsFocus] = useState(false);
  const [random] = useState(Math.random());
  const [paddingTop, setPaddingTop] = useState(0);
  const [coordinate, setCoordinate] = useState({ left: 0, top: 0, opacity: 0 });
  const minHeight = 45;
  useEffect(() => {
    if (inputRef) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const fontSizeLocal = parseInt(window.getComputedStyle(inputRef.current).fontSize, 10);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const height = inputRef.current.offsetHeight;
      const paddingTopLocal = height - fontSizeLocal - offsetBottom;
      setPaddingTop(paddingTopLocal);
      getInputRef && getInputRef(inputRef);
    }
  }, [inputRef]);

  const setStyleInput = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const bottomPadding = wrapProps?.height <= minHeight ? 0 : offsetBottom;
    let paddingLocal = {
      padding: `${paddingTop}px ${offsetRight}px ${bottomPadding}px ${
        offsetLeft + (currency ? fontSize / 2 : 0) + (startAdornment ? 40 : 0)
      }px`,
    } as { padding: string; paddingRight?: number };

    if (!label) {
      paddingLocal = { padding: `0px ${offsetRight}px` };
    }

    if (isSelect && label) {
      paddingLocal = {
        padding: `${paddingTop}px ${selectOffsetRight}px ${bottomPadding}px ${
          offsetLeft + (currency ? fontSize / 2 : 0) + (startAdornment ? 30 : 0)
        }px`,
      };
    }
    let style = {
      ...paddingLocal,
      fontSize,
    } as StyleType;

    if (disabled) {
      style = { ...style, opacity: 0.4, background: '#f7f7f7', zIndex: 0, border: '1px solid #6b7b83' };
    }

    if (isError) {
      style = { ...style, color: red, border: `1px solid ${red}` };
    }

    return style;
  }, [
    isFocus,
    paddingTop,
    offsetBottom,
    isError,
    disabled,
    offsetLeft,
    fontSize,
    wrapProps,
    label,
    selectOffsetRight,
    isSelect,
    currency,
  ]);

  const setStyleLabel = useCallback(
    (val: string) => {
      let style = { left: offsetLeft } as StyleType;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const top = wrapProps?.height <= minHeight ? 3 : offsetTop;

      if (disabled) {
        style = { ...style, opacity: 0.4 };
      }

      if (isFocus || value) {
        style = { ...style, top };
      }

      if (val === '' && !isFocus) {
        style = { ...style, fontSize: fontSizeLabel, top: `calc(50% - ${fontSizeLabel / 2}px)` };
      }

      return style;
    },
    [isFocus, value, offsetLeft, fontSize, disabled, fontSizeLabel],
  );

  return (
    <div
      className={styles.inputKitWrap}
      style={wrapProps}
      onMouseLeave={() => {
        if (!disabled && tooltip.length === 0) return;
        setCoordinate((prev) => ({ ...prev, opacity: 0 }));
      }}
      {...(dataCy && { 'data-cy': dataCy })}
    >
      <div
        onMouseMove={(e) => {
          if (!disabled && tooltip.length === 0) return;
          const target = e.target as HTMLElement;
          const coords = target.getBoundingClientRect();
          const left = e.clientX - coords.left + 10;
          const top = e.clientY - coords.top + 10;
          setCoordinate({ left, top, opacity: 1 });
        }}
        className={styles.tooltipWrap}
      />
      {startAdornment}
      <input
        onPaste={onPaste}
        name={name}
        disabled={disabled}
        ref={inputRef}
        style={setStyleInput()}
        className={styles.inputKit}
        type={type}
        id={labelId + random}
        onFocus={(e) => {
          onFocus && onFocus(e);
          setIsFocus(true);
        }}
        value={value}
        onChange={onChange}
        onBlur={(e) => {
          onBlurHandler(e);
          setIsFocus(false);
        }}
        placeholder={isFocus || isSelect ? placeholder : ''}
      />
      {disabled && tooltip.length > 0 && (
        <div
          className={styles.tooltip}
          style={{
            transform: `translate3d(${coordinate.left}px, ${coordinate.top}px, 0)`,
            opacity: coordinate.opacity,
          }}
        >
          {tooltip}
        </div>
      )}
      {currency && (isFocus || value.length > 0) && (
        <span
          style={{
            position: 'absolute',
            top: paddingTop,
            left: 16,
            lineHeight: 1,
            color: '#A7AFB7',
            fontSize,
            zIndex: 1,
          }}
        >
          {getCurrency(currency)}
        </span>
      )}
      {label && (
        <label htmlFor={labelId + random} className={styles.inputKitLabel} style={setStyleLabel(value)}>
          {`${label}${required ? '*' : ''}`}
        </label>
      )}
      {helperText.length > 0 && (
        <div
          className={styles.inputKitHelper}
          style={{
            color: isError ? red : '#A7AFB7',
            top: ' 100%',
            left: offsetLeft,
            lineHeight: '16px',
            ...helperTextStyle,
          }}
        >
          {helperText}
        </div>
      )}
      {element}
    </div>
  );
};
