import { formatAmount } from 'helpers/formatters';

export const amountWithCurr = (curr: string, amount: string) => {
  return `${curr === 'EUR' ? '€' : '$'} ${formatAmount(Number(amount))}`;
};

export const replaceInString = (str: string) => {
  return str.replace(/_/g, ' ');
};

export const formatInterest = (str: string) => {
  return str
    .split(' ')
    .map((el, i) => {
      if (i === 0) {
        return;
      }

      if (i === 1) {
        return el + '%';
      }

      return el;
    })
    .join(' ');
};
