import baseRequest from 'queries/baseRequest';
import endpoints from 'consts/endpoints';
import { AOStatus } from 'components/common/Status';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const editSchedules = (aoId: string, body: any) =>
  baseRequest({ path: endpoints.getScheduleForFutureReceivablesUrl(aoId), options: { method: 'PUT', body } });

export const changeStatusAO = (orderId: string, newStatus: AOStatus) =>
  baseRequest({
    path: endpoints.getAvailableReceivablesUrl(orderId, { newStatus }),
    options: {
      method: 'PATCH',
    },
  }).then((result) => {
    return result?.status;
  });

export const changeStatusAONew = (orderId: string, newStatus: AOStatus) =>
  baseRequest({
    path: endpoints.getAOUrl(orderId, { newStatus }),
    options: {
      method: 'PATCH',
    },
  }).then((result) => {
    return result?.status;
  });
